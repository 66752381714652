import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { AppService } from 'src/app/app.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  public isDisabled = false;
  public form = new FormGroup({});
  constructor(
    public formBuilder: FormBuilder,
    private appService: AppService,
    private spinner: NgxSpinnerService
  ) {}

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
      lastName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
      email: [
        '',
        [Validators.required, Validators.email, Validators.minLength(2), Validators.maxLength(255)],
      ],
      message: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(500)]],
      mobile: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(13)]],
    });
  }

  public onSend() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      alert('Invalid form');
      return;
    }
    this.isDisabled = true;
    this.spinner.show();
    this.appService
      .createContact(this.form.value)
      .then(() => {
        alert('Save Successfully');
        this.form.reset();
      })
      .catch(() => confirm('Please Try After Some Time.'))
      .finally(() => {
        this.isDisabled = false;
        setTimeout(() => {
          this.spinner.hide();
        }, 1000);
      });
  }

  public get firstName(): AbstractControl {
    return this.form.controls.firstName;
  }

  public get lastName(): AbstractControl {
    return this.form.controls.lastName;
  }

  public get email(): AbstractControl {
    return this.form.controls.email;
  }

  public get mobile(): AbstractControl {
    return this.form.controls.mobile;
  }

  public get message(): AbstractControl {
    return this.form.controls.message;
  }
}
