<section class="video-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
  <div class="container">
    <div class="video-content">
      <h2>Best Place of Culinary</h2>
      <a
        href="https://storage.googleapis.com/teckat-dev.appspot.com/kumarhilife.in/kumarhilife_keonjhar"
        class="video-btn popup-youtube"
        ><i class="icofont-play"></i
      ></a>
    </div>
  </div>
</section>
