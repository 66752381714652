<header class="top-area">
  <div class="top-bar">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-6">
          <div class="call-to-action">
            <p>
              <i class="icofont-envelope"></i> Email:
              <a href="mailto: kumarhilife@gmail.com"> kumarhilife@gmail.com</a>
            </p>
            <p>
              <a href="tel:06766255245"><i class="icofont-phone"></i>06766 255 245</a> /
              <a href="tel:+917205245144"> +91 720 524 5144</a>
            </p>
          </div>
        </div>

        <div class="col-lg-6 col-md-6">
          <ul class="top-social">
            <li>
              <a href="https://www.facebook.com/kumarhilife/" target="_blank"
                ><i class="icofont-facebook"></i
              ></a>
            </li>
            <li>
              <a href="https://www.instagram.com/hotelkumarhilife/" target="_blank"
                ><i class="icofont-instagram"></i
              ></a>
            </li>
          </ul>

          <div class="opening-hours">
            <p><i class="icofont-wall-clock"></i> Opening Hours : 9:00am - 10:00pm</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <nav class="navbar navbar-expand-lg middle-logo-navbar navbar-light bg-light transparent-navbar">
    <div class="container">
      <a class="navbar-brand" routerLink="/"
        ><img src="assets/img/white-logo.png" alt="white-logo"
      /></a>
      <a class="navbar-brand black-logo" routerLink="/"
        ><img src="assets/img/black-logo.png" alt="black-logo"
      /></a>

      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="nav-item"><a class="nav-link" href="#home">Home</a></li>
          <li class="nav-item"><a class="nav-link" href="#about">About</a></li>
          <li class="nav-item"><a class="nav-link" href="#offer">Offer</a></li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="https://storage.googleapis.com/cdn.onsi.in/kumar_hotel/Kumar%20Hotel.pdf"
              target="_blank"
              >Menu</a
            >
          </li>
          <li class="nav-item logo">
            <a routerLink="/">
              <img src="assets/img/white-logo.png" alt="white-logo" />
              <img src="assets/img/black-logo.png" alt="black-logo" class="black-logo" />
            </a>
          </li>
          <li class="nav-item"><a class="nav-link" href="#gallery">Gallery</a></li>
          <li class="nav-item"><a class="nav-link" href="#book">Book Now</a></li>
          <li class="nav-item"><a class="nav-link" href="#blog">Assistance</a></li>
          <li class="nav-item"><a class="nav-link" href="#contact">Contact</a></li>
        </ul>
      </div>
    </div>
  </nav>
</header>
