import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(private firestore: AngularFirestore) {}

  public createContact(payload: { [key: string]: any }) {
    payload.createdAt = firebase.default.firestore.Timestamp.now();
    payload.updatedAt = firebase.default.firestore.Timestamp.now();
    payload.status = true;
    return this.firestore.collection('contacts').add(payload);
  }

  public createBooking(payload: { [key: string]: any }) {
    payload.createdAt = firebase.default.firestore.Timestamp.now();
    payload.updatedAt = firebase.default.firestore.Timestamp.now();
    payload.status = true;
    return this.firestore.collection('bookings').add(payload);
  }
}
