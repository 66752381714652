<app-header-three></app-header-three>
<div id="home" class="main home-slides owl-carousel owl-theme">
  <div class="main-banner item-bg-two three cta-area jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="main-banner-content">
            <h1>Kumar Hi-Life</h1>
            <h3>First Roof Top Restaurant in Keonjhar</h3>
            <a href="#contact" class="btn btn-primary">Get in Touch</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="main-banner item-bg-three two cta-area jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="main-banner-content">
            <h1>Perfect Stay</h1>
            <h3>Enjoy Your Holidays</h3>
            <a href="#book" class="btn btn-primary">Book Your Room</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-features></app-features>

<app-video></app-video>
<app-about></app-about>

<app-offer></app-offer>

<!-- <section id="menu" class="menu-area-two ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Discover</span>
            <h2>Our Menu</h2>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="single-restaurant-menu">
                    <div class="menu-image">
                        <img src="assets/img/menu-img1.jpg" alt="menu-image">
                    </div>

                    <div class="menu-content">
                        <h3>Mussels Salad <span class="price">$23.00</span></h3>
                        <ul>
                            <li>Juice Fruit</li>
                            <li>Cherry</li>
                            <li>Milk</li>
                            <li>Vegetable</li>
                            <li>Potato</li>
                            <li>Tomato</li>
                            <li>Chilli</li>
                            <li>Sesame</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-restaurant-menu">
                    <div class="menu-image">
                        <img src="assets/img/menu-img2.jpg" alt="menu-image">
                    </div>

                    <div class="menu-content">
                        <h3>Sandwich Soup <span class="price">$20.00</span></h3>
                        <ul>
                            <li>Juice Fruit</li>
                            <li>Cherry</li>
                            <li>Milk</li>
                            <li>Vegetable</li>
                            <li>Potato</li>
                            <li>Tomato</li>
                            <li>Chilli</li>
                            <li>Sesame</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-restaurant-menu">
                    <div class="menu-image">
                        <img src="assets/img/menu-img3.jpg" alt="menu-image">
                    </div>

                    <div class="menu-content">
                        <h3>Bread <span class="price">$23.00</span></h3>
                        <ul>
                            <li>Juice Fruit</li>
                            <li>Cherry</li>
                            <li>Milk</li>
                            <li>Vegetable</li>
                            <li>Potato</li>
                            <li>Tomato</li>
                            <li>Chilli</li>
                            <li>Sesame</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-restaurant-menu">
                    <div class="menu-image">
                        <img src="assets/img/menu-img4.jpg" alt="menu-image">
                    </div>

                    <div class="menu-content">
                        <h3>Steak <span class="price">$35.00</span></h3>
                        <ul>
                            <li>Juice Fruit</li>
                            <li>Cherry</li>
                            <li>Milk</li>
                            <li>Vegetable</li>
                            <li>Potato</li>
                            <li>Tomato</li>
                            <li>Chilli</li>
                            <li>Sesame</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-restaurant-menu">
                    <div class="menu-image">
                        <img src="assets/img/menu-img1.jpg" alt="menu-image">
                    </div>

                    <div class="menu-content">
                        <h3>Tuna Steak <span class="price">$54.00</span></h3>
                        <ul>
                            <li>Juice Fruit</li>
                            <li>Cherry</li>
                            <li>Milk</li>
                            <li>Vegetable</li>
                            <li>Potato</li>
                            <li>Tomato</li>
                            <li>Chilli</li>
                            <li>Sesame</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-restaurant-menu">
                    <div class="menu-image">
                        <img src="assets/img/menu-img2.jpg" alt="menu-image">
                    </div>

                    <div class="menu-content">
                        <h3>Fish <span class="price">$23.00</span></h3>
                        <ul>
                            <li>Juice Fruit</li>
                            <li>Cherry</li>
                            <li>Milk</li>
                            <li>Vegetable</li>
                            <li>Potato</li>
                            <li>Tomato</li>
                            <li>Chilli</li>
                            <li>Sesame</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-restaurant-menu">
                    <div class="menu-image">
                        <img src="assets/img/menu-img3.jpg" alt="menu-image">
                    </div>

                    <div class="menu-content">
                        <h3>Rice <span class="price">$23.00</span></h3>
                        <ul>
                            <li>Juice Fruit</li>
                            <li>Cherry</li>
                            <li>Milk</li>
                            <li>Vegetable</li>
                            <li>Potato</li>
                            <li>Tomato</li>
                            <li>Chilli</li>
                            <li>Sesame</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-restaurant-menu">
                    <div class="menu-image">
                        <img src="assets/img/menu-img4.jpg" alt="menu-image">
                    </div>

                    <div class="menu-content">
                        <h3>Spaghetti <span class="price">$23.00</span></h3>
                        <ul>
                            <li>Juice Fruit</li>
                            <li>Cherry</li>
                            <li>Milk</li>
                            <li>Vegetable</li>
                            <li>Potato</li>
                            <li>Tomato</li>
                            <li>Chilli</li>
                            <li>Sesame</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="line-bg"><img src="assets/img/line.png" alt="line"></div>
</section> -->

<!-- <app-stunning-things></app-stunning-things> -->
<app-gallery></app-gallery>

<app-book-table></app-book-table>

<!-- <app-chefs></app-chefs> -->

<!-- <app-cta></app-cta> -->

<section id="blog" class="blog-area ptb-100">
  <div class="container">
    <div class="section-title">
      <span>We are there</span>
      <h2>For Your Assistance</h2>
    </div>

    <div class="blog-slides owl-carousel owl-theme">
      <div class="single-blog-post">
        <a class="post-image"><img src="assets/img/blog-img1.jpg" alt="blog-image" /></a>

        <div class="blog-post-content">
          <!-- <ul>
            <li><i class="icofont-ui-user"></i> <a href="#">Admin</a></li>
            <li><i class="icofont-wall-clock"></i> January 16, 2020</li>
          </ul> -->
          <h3>Our Quick Services</h3>
          <p>
            Enjoy Your Free Breakfast and Wi-Fi Host the event of your dreams at our hotel, with
            your special guests and ballroom, rooftop. Let our wedding planner, events planner, take
            care of the details
          </p>
        </div>
      </div>
      <div class="single-blog-post">
        <a class="post-image"><img src="assets/img/blog-img2.jpg" alt="blog-image" /></a>

        <div class="blog-post-content">
          <!-- <ul>
            <li><i class="icofont-ui-user"></i> <a href="#">Admin</a></li>
            <li><i class="icofont-wall-clock"></i> January 16, 2020</li>
          </ul> -->
          <h3>Accomodation</h3>
          <p>
            We are here with our best accommodation facility at Keonjhar with elegant rooms and
            scenic views all around. Enjoy the comfort and food with a great taste, open lounge and
            much more.
          </p>
        </div>
      </div>
      <div class="single-blog-post">
        <a class="post-image"><img src="assets/img/blog-img3.jpg" alt="blog-image" /></a>

        <div class="blog-post-content">
          <!-- <ul>
            <li><i class="icofont-ui-user"></i> <a href="#">Admin</a></li>
            <li><i class="icofont-wall-clock"></i> January 16, 2020</li>
          </ul> -->
          <h3>Sanitized Stays</h3>
          <p>
            We assure you for finest, sanitized and hygiene stay at Kumar Hi-Life. A perfect stay
            for you, with safety; reach our hotel and get a dream room at right budget within no
            time.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<app-feedback></app-feedback>

<!-- <app-partner></app-partner> -->

<app-contact></app-contact>

<app-instagram></app-instagram>
