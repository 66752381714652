<section class="feedback-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
  <div class="container">
    <div class="section-title">
      <span>Testimonials</span>
      <h2>Our Client Feedback</h2>
    </div>

    <div class="feedback-slides owl-carousel owl-theme">
      <div class="single-feedback">
        <p>
          Best in rooms around keonjhar dist. With a nice roof top restaurant.. Food service was
          also vry good..
        </p>

        <div class="client-info">
          <h3>Anurag Sharma</h3>
          <span>on <i class="icofont-google-plus"></i></span>
        </div>
      </div>

      <div class="single-feedback">
        <p>Rooms are good neat and clean</p>

        <div class="client-info">
          <h3>P.KUMAR SWAMY</h3>
          <span>on <i class="icofont-google-plus"></i></span>
        </div>
      </div>

      <div class="single-feedback">
        <p>
          Rooms good staff good price good and location is good. This hotel in market beside the
          road and if u want any other products like medicine or any other thing it available in
          near.
        </p>

        <div class="client-info">
          <h3>Rajat Sengupta</h3>
          <span>on <i class="icofont-google-plus"></i></span>
        </div>
      </div>
      <div class="single-feedback">
        <p>
          Good rooms, well maintained. Can be booked through Makemytrip. But bit expensive for the
          place.
        </p>

        <div class="client-info">
          <h3>Rupam Ranjan Rana</h3>
          <span>on <i class="icofont-google-plus"></i></span>
        </div>
      </div>
      <div class="single-feedback">
        <p>
          In a word "quite good"..food quality sud b improved a little..else great ambience,nice
          room..
        </p>

        <div class="client-info">
          <h3>Enakshi Maity</h3>
          <span>on <i class="icofont-google-plus"></i></span>
        </div>
      </div>
    </div>
  </div>
</section>
