<section id="gallery" class="gallery-area ptb-100 pb-0">
  <div class="container">
    <div class="section-title">
      <span>Gallery</span>
      <h2>Take A Glance</h2>
    </div>
  </div>

  <div class="row m-0">
    <div class="col-lg-3 col-sm-6 col-md-6 p-0">
      <div class="single-image">
        <img src="assets/img/gallery/gallery-img11.jpg" alt="gallery-img" />
        <a href="assets/img/gallery/gallery-img11.jpg" class="popup-btn"
          ><i class="icofont-search-restaurant"></i
        ></a>

        <div class="image-content">
          <h3><a href="#">Happy Birthday</a></h3>
        </div>
      </div>
    </div>

    <div class="col-lg-3 col-sm-6 col-md-6 p-0">
      <div class="single-image">
        <img src="assets/img/gallery/gallery-img12.jpg" alt="gallery-img" />
        <a href="assets/img/gallery/gallery-img12.jpg" class="popup-btn"
          ><i class="icofont-search-restaurant"></i
        ></a>

        <div class="image-content">
          <h3><a href="#">Happy Birthday</a></h3>
        </div>
      </div>
    </div>

    <div class="col-lg-3 col-sm-6 col-md-6 p-0">
      <div class="single-image">
        <img src="assets/img/gallery/gallery-img13.jpg" alt="gallery-img" />
        <a href="assets/img/gallery/gallery-img13.jpg" class="popup-btn"
          ><i class="icofont-search-restaurant"></i
        ></a>

        <div class="image-content">
          <h3><a href="#">Happy Birthday</a></h3>
        </div>
      </div>
    </div>

    <div class="col-lg-3 col-sm-6 col-md-6 p-0">
      <div class="single-image">
        <img src="assets/img/gallery/gallery-img14.jpg" alt="gallery-img" />
        <a href="assets/img/gallery/gallery-img14.jpg" class="popup-btn"
          ><i class="icofont-search-restaurant"></i
        ></a>

        <div class="image-content">
          <h3><a href="#">Happy Birthday</a></h3>
        </div>
      </div>
    </div>

    <div class="col-lg-3 col-sm-6 col-md-6 p-0">
      <div class="single-image">
        <img src="assets/img/gallery/gallery-img1.jpg" alt="gallery-img" />
        <a href="assets/img/gallery/gallery-img1.jpg" class="popup-btn"
          ><i class="icofont-search-restaurant"></i
        ></a>

        <div class="image-content">
          <h3><a href="#">Starters</a></h3>
        </div>
      </div>
    </div>

    <div class="col-lg-3 col-sm-6 col-md-6 p-0">
      <div class="single-image">
        <img src="assets/img/gallery/gallery-img2.jpg" alt="gallery-img" />
        <a href="assets/img/gallery/gallery-img2.jpg" class="popup-btn"
          ><i class="icofont-search-restaurant"></i
        ></a>

        <div class="image-content">
          <h3><a href="#">Main Course</a></h3>
        </div>
      </div>
    </div>

    <div class="col-lg-3 col-sm-6 col-md-6 p-0">
      <div class="single-image">
        <img src="assets/img/gallery/gallery-img3.jpg" alt="gallery-img" />
        <a href="assets/img/gallery/gallery-img3.jpg" class="popup-btn"
          ><i class="icofont-search-restaurant"></i
        ></a>

        <div class="image-content">
          <h3><a href="#">Combos</a></h3>
        </div>
      </div>
    </div>

    <div class="col-lg-3 col-sm-6 col-md-6 p-0">
      <div class="single-image">
        <img src="assets/img/gallery/gallery-img4.jpg" alt="gallery-img" />
        <a href="assets/img/gallery/gallery-img4.jpg" class="popup-btn"
          ><i class="icofont-search-restaurant"></i
        ></a>

        <div class="image-content">
          <h3><a href="#">Tandoors</a></h3>
        </div>
      </div>
    </div>

    <div class="col-lg-3 col-sm-6 col-md-6 p-0">
      <div class="single-image">
        <img src="assets/img/gallery/gallery-img5.jpg" alt="gallery-img" />
        <a href="assets/img/gallery/gallery-img5.jpg" class="popup-btn"
          ><i class="icofont-search-restaurant"></i
        ></a>

        <div class="image-content">
          <h3><a href="#">Elegant Rooms</a></h3>
        </div>
      </div>
    </div>

    <div class="col-lg-3 col-sm-6 col-md-6 p-0">
      <div class="single-image">
        <img src="assets/img/gallery/gallery-img6.jpg" alt="gallery-img" />
        <a href="assets/img/gallery/gallery-img6.jpg" class="popup-btn"
          ><i class="icofont-search-restaurant"></i
        ></a>

        <div class="image-content">
          <h3><a href="#">Remotely Exteriors</a></h3>
        </div>
      </div>
    </div>

    <div class="col-lg-3 col-sm-6 col-md-6 p-0">
      <div class="single-image">
        <img src="assets/img/gallery/gallery-img7.jpg" alt="gallery-img" />
        <a href="assets/img/gallery/gallery-img7.jpg" class="popup-btn"
          ><i class="icofont-search-restaurant"></i
        ></a>

        <div class="image-content">
          <h3><a href="#">Air Conditioned Stays</a></h3>
        </div>
      </div>
    </div>

    <div class="col-lg-3 col-sm-6 col-md-6 p-0">
      <div class="single-image">
        <img src="assets/img/gallery/gallery-img8.jpg" alt="gallery-img" />
        <a href="assets/img/gallery/gallery-img8.jpg" class="popup-btn"
          ><i class="icofont-search-restaurant"></i
        ></a>

        <div class="image-content">
          <h3><a href="#">Exclusive Stays</a></h3>
        </div>
      </div>
    </div>
  </div>
</section>
