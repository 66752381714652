<section class="instagram-area">
  <div class="instagram-title">
    <h3>
      <a href="https://www.facebook.com/kumarhilife/" target="_blank"
        ><i class="icofont-facebook"></i> Follow Us On @kumarhilife</a
      >
    </h3>
    <h3 class="pt-1">
      <a href="https://www.instagram.com/hotelkumarhilife/" target="_blank"
        ><i class="icofont-instagram"></i> Follow Us On @hotelkumarhilife</a
      >
    </h3>
    <!-- <h3>
      <a href="#" target="_blank" class="margin"
        ><i class="icofont-instagram"></i> Follow Us On @fryio</a
      >
    </h3> -->
  </div>
  <div class="instagram-slides owl-carousel owl-theme">
    <div class="instagram-item">
      <img src="assets/img/gallery/gallery-img1.jpg" alt="instagram-image" />
    </div>

    <div class="instagram-item">
      <img src="assets/img/gallery/gallery-img2.jpg" alt="instagram-image" />
    </div>

    <div class="instagram-item">
      <img src="assets/img/gallery/gallery-img3.jpg" alt="instagram-image" />
    </div>

    <div class="instagram-item">
      <img src="assets/img/gallery/gallery-img4.jpg" alt="instagram-image" />
    </div>

    <div class="instagram-item">
      <img src="assets/img/gallery/gallery-img5.jpg" alt="instagram-image" />
    </div>

    <div class="instagram-item">
      <img src="assets/img/gallery/gallery-img6.jpg" alt="instagram-image" />
    </div>

    <div class="instagram-item">
      <img src="assets/img/gallery/gallery-img7.jpg" alt="instagram-image" />
    </div>

    <div class="instagram-item">
      <img src="assets/img/gallery/gallery-img8.jpg" alt="instagram-image" />
    </div>

    <div class="instagram-item">
      <img src="assets/img/gallery/gallery-img9.jpg" alt="instagram-image" />
    </div>

    <div class="instagram-item">
      <img src="assets/img/gallery/gallery-img10.jpg" alt="instagram-image" />
    </div>

    <div class="instagram-item">
      <img src="assets/img/gallery/gallery-img5.jpg" alt="instagram-image" />
    </div>

    <div class="instagram-item">
      <img src="assets/img/gallery/gallery-img6.jpg" alt="instagram-image" />
    </div>
  </div>
</section>
