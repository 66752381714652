<footer class="footer-area">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-6">
        <p>Copyright <i class="icofont-copyright"></i>2020 Kumar Hi-Life. All rights reserved</p>
      </div>

      <div class="col-lg-6 col-md-6">
        <ul>
          <li><a routerLink="/">Terms & Conditions</a></li>
          <li><a routerLink="/">Privacy Policy</a></li>
          <li><a href="https://teckat.com" target="_blank">Developed By : TECKAT.COM</a></li>
        </ul>
      </div>
    </div>
  </div>
</footer>

<app-reservation-modal></app-reservation-modal>
