<section id="about" class="story-area ptb-100">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="story-image">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
              <img src="assets/img/about-img1.jpg" alt="image" />
              <img src="assets/img/about-img2.jpg" alt="image" />
            </div>

            <div class="col-lg-6 col-md-6">
              <img src="assets/img/about-img3.jpg" alt="image" />
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="story-content">
          <div class="section-title">
            <span>Our Moral</span>
            <h2>Greetings from Us!</h2>
          </div>
          <p>
            We are here to serve you best food with great taste and we assure you for a great
            ambiance ever. A rooftop restaurant with scenic view that refreshes your mood. A great
            place with perfect flavors of food, it's just perfect. We do sanitize our place
            uniformly before you are at our place.
          </p>

          <div class="story-quote">
            <div class="quote-content">
              <p>
                "Celebrate your birthday's, anniversaries and all Special days with us. Memories are
                sweet and beautiful when you choose a perfect place."
              </p>
              <!-- <img src="assets/img/about_sign.png" alt="img" /> -->
            </div>
            <!-- <div class="quote-info">
              <img src="assets/img/story-1.jpg" alt="story" />
              <h3>John Smith</h3>
              <span>Master Chef</span>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="particles-js-circle-bubble-1"></div>
  <div class="line-bg"><img src="assets/img/line.png" alt="line" /></div>
</section>
