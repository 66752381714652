<div class="modal fade" id="reservationModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Room Booking Reservation Form</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i class="icofont-close"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <form class="modalForm" [formGroup]="form">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <input type="text" class="form-control" formControlName="name" placeholder="Name" />
                <div class="text-danger text-center" *ngIf="name.touched && name.errors">
                  Invalid name
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <input
                  type="email"
                  class="form-control"
                  formControlName="email"
                  placeholder="Email"
                />
                <div class="text-danger text-center" *ngIf="email.touched && email.errors">
                  Invalid email
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <input
                  type="tel"
                  class="form-control"
                  formControlName="mobile"
                  placeholder="Mobile"
                />
                <div class="text-danger text-center" *ngIf="mobile.touched && mobile.errors">
                  Invalid mobile
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <input
                  type="date"
                  class="form-control"
                  formControlName="date"
                  placeholder="01/02/2019"
                />
                <div class="text-danger text-center" *ngIf="date.touched && date.errors">
                  Invalid date
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <input
                  type="time"
                  class="form-control"
                  formControlName="time"
                  placeholder="3:00PM"
                />
                <div class="text-danger text-center" *ngIf="time.touched && time.errors">
                  Invalid time
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <select class="form-control" formControlName="person">
                  <option [value]="1">1 Person</option>
                  <option [value]="2">2 Person</option>
                  <option [value]="3">3 Person</option>
                  <option [value]="4">4 Person</option>
                  <option [value]="5">5 Person</option>
                </select>
                <div class="text-danger text-center" *ngIf="person.touched && person.errors">
                  Invalid person
                </div>
              </div>
            </div>

            <div class="col-lg-12 col-md-12">
              <button
                type="button"
                class="btn btn-primary"
                (click)="onBookATable()"
                [disabled]="isDisabled"
              >
                Book Room Now
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
