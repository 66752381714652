import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
// import { HomeOneComponent } from './components/pages/home-one/home-one.component';
// import { HeaderOneComponent } from './components/layouts/header-one/header-one.component';
import { InstagramComponent } from './components/layouts/instagram/instagram.component';
import { AboutComponent } from './components/layouts/about/about.component';
import { ReservationModalComponent } from './components/layouts/reservation-modal/reservation-modal.component';
import { VideoComponent } from './components/layouts/video/video.component';
import { BookTableComponent } from './components/layouts/book-table/book-table.component';
// import { ChefsComponent } from './components/layouts/chefs/chefs.component';
// import { CtaComponent } from './components/layouts/cta/cta.component';
import { GalleryComponent } from './components/layouts/gallery/gallery.component';
import { FeaturesComponent } from './components/layouts/features/features.component';
import { ContactComponent } from './components/layouts/contact/contact.component';
import { FeedbackComponent } from './components/layouts/feedback/feedback.component';
// import { PartnerComponent } from './components/layouts/partner/partner.component';
// import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
// import { BlogComponent } from './components/pages/blog/blog.component';
import { StunningThingsComponent } from './components/layouts/stunning-things/stunning-things.component';
import { OfferComponent } from './components/layouts/offer/offer.component';
// import { HeaderTwoComponent } from './components/layouts/header-two/header-two.component';
import { HeaderThreeComponent } from './components/layouts/header-three/header-three.component';
// import { HeaderFourComponent } from './components/layouts/header-four/header-four.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeTwoComponent,
    HeaderThreeComponent,
    PreloaderComponent,
    FooterComponent,
    // HomeOneComponent,
    // HeaderOneComponent,
    InstagramComponent,
    AboutComponent,
    ReservationModalComponent,
    VideoComponent,
    BookTableComponent,
    // ChefsComponent,
    // CtaComponent,
    GalleryComponent,
    FeaturesComponent,
    ContactComponent,
    FeedbackComponent,
    // PartnerComponent,
    // BlogDetailsComponent,
    // BlogComponent,
    StunningThingsComponent,
    OfferComponent,
    // HeaderTwoComponent,
    // HeaderFourComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp({
      apiKey: 'AIzaSyDnboJeJdmMFKlDO9losXKWE2z0FQjpypY',
      authDomain: 'kumar-hotel.firebaseapp.com',
      databaseURL: 'https://kumar-hotel-default-rtdb.firebaseio.com',
      projectId: 'kumar-hotel',
      storageBucket: 'kumar-hotel.appspot.com',
      messagingSenderId: '594905667792',
      appId: '1:594905667792:web:a7cf98f239bc0f1761a6d5',
    }),
    AngularFirestoreModule,
    AppRoutingModule,
    NgxSpinnerModule,
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
