<section id="offer" class="offer-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
  <div class="container">
    <div class="section-title">
      <span>Beautiful Scenic</span>
      <h2>Celebration Begins!</h2>
    </div>

    <div class="offer-slides owl-carousel owl-theme">
      <div class="single-offer">
        <a href="#"><img src="assets/img/offer-img1.jpg" alt="offer-img" /></a>

        <div class="offer-content">
          <h3><span>Celebrate your day</span> Birthday's | Kitty Party's | Anniversaries</h3>
          <p>
            Celebrate Your Special' Days with a great ambiance and Your loved One's. Find our place
            at your budget. Connect us for any bookings, we are here to help you find right corners.
          </p>
          <!-- <a href="#" class="btn btn-primary">Order Now</a> -->
        </div>
      </div>

      <div class="single-offer">
        <a href="#"><img src="assets/img/offer-img2.jpg" alt="offer-img" /></a>

        <div class="offer-content">
          <h3><span>Workholic</span>Conference | Meetings | Get Together</h3>

          <p>
            Wanna hold a get together, meetings and conferences with perfect lights, a cup of
            coffee, little snacks and a great mood. We shall help you be better & comfortable at our
            place.
          </p>
          <!-- <a href="#" class="btn btn-primary">Order Now</a> -->
        </div>
      </div>
    </div>
  </div>
</section>
