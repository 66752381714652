<section id="contact" class="contact-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6">
        <div class="contact-box">
          <h3>Contact Us</h3>
          <p>
            <a href="#" target="_blank"
              ><i class="icofont-google-map"></i> Hotel Kumar HI LIFE, New Market, Gandhi Chowk,
              Keonjhar - Odisha, India - 758001</a
            >
          </p>
          <p>
            <a href="tel:06766255245"><i class="icofont-phone"></i>06766 255 245</a> /
            <a href="tel:+917205245144"> +91 720 524 5144</a>
          </p>
          <p>
            <a href="mailto: kumarhilife@gmail.com"
              ><i class="icofont-envelope"></i> kumarhilife@gmail.com</a
            >
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6">
        <div class="contact-box">
          <h3>Opening Hours</h3>
          <p class="opening-hours">Sun - Sat <span>09:00am - 10:00pm</span></p>
        </div>
      </div>

      <div class="col-lg-12 col-md-12">
        <form id="contactForm" [formGroup]="form">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  formControlName="firstName"
                  placeholder="First name"
                />
                <div class="text-danger text-center" *ngIf="firstName.touched && firstName.errors">
                  Invalid first name
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <input
                  formControlName="lastName"
                  type="text"
                  class="form-control"
                  placeholder="Last name"
                />
                <div class="text-danger text-center" *ngIf="lastName.touched && lastName.errors">
                  Invalid last name
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <input
                  type="email"
                  class="form-control"
                  formControlName="email"
                  placeholder="Your email"
                />
                <div class="text-danger text-center" *ngIf="email.touched && email.errors">
                  Invalid email
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  formControlName="mobile"
                  placeholder="Your phone"
                />
                <div class="text-danger text-center" *ngIf="mobile.touched && mobile.errors">
                  Invalid phone
                </div>
              </div>
            </div>

            <div class="col-lg-12 col-md-12">
              <div class="form-group">
                <textarea
                  name="message"
                  cols="30"
                  rows="5"
                  formControlName="message"
                  class="form-control"
                  placeholder="Your message..."
                ></textarea>
                <div class="text-danger text-center" *ngIf="message.touched && message.errors">
                  Invalid message
                </div>
              </div>
            </div>

            <div class="col-lg-12 col-md-12">
              <button
                type="button"
                class="btn btn-primary"
                (click)="onSend()"
                [disabled]="isDisabled"
              >
                Send Message
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
