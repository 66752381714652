import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from 'src/app/app.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-reservation-modal',
  templateUrl: './reservation-modal.component.html',
  styleUrls: ['./reservation-modal.component.scss'],
})
export class ReservationModalComponent implements OnInit {
  public isDisabled = false;
  public form = new FormGroup({});
  constructor(
    private appService: AppService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService
  ) {}

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
      email: [
        '',
        [Validators.required, Validators.email, Validators.minLength(2), Validators.maxLength(255)],
      ],
      mobile: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(13)]],
      date: ['', Validators.required],
      time: ['', Validators.required],
      person: [1],
    });
  }

  public onBookATable(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.isDisabled = true;
    this.spinner.show();
    this.appService
      .createBooking(this.form.value)
      .then(() => {
        alert('Save Successfully');
        this.form.reset();
      })
      .catch(() => confirm('Please Try After Some Time.'))
      .finally(() => {
        this.isDisabled = false;
        setTimeout(() => {
          this.spinner.hide();
        }, 1000);
      });
  }

  public get name(): AbstractControl {
    return this.form.get('name');
  }

  public get email(): AbstractControl {
    return this.form.get('email');
  }

  public get mobile(): AbstractControl {
    return this.form.get('mobile');
  }

  public get time(): AbstractControl {
    return this.form.get('time');
  }

  public get date(): AbstractControl {
    return this.form.get('date');
  }

  public get person(): AbstractControl {
    return this.form.get('person');
  }
}
