<section class="features-area ptb-100 pb-0">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-4 col-sm-6 col-md-6">
        <div class="single-features">
          <div class="icon">
            <i class="icofont-restaurant"></i>
          </div>
          <div class="features-content">
            <h3>Starters</h3>
            <p>Start with something Lip Smacking food that gives crispiness in taste.</p>
          </div>
        </div>

        <div class="single-features">
          <div class="icon">
            <i class="icofont-chicken"></i>
          </div>
          <div class="features-content">
            <h3>Tandoors</h3>
            <p>The cravings for tandoor's goes on Increasing, it has a great taste.</p>
          </div>
        </div>

        <div class="single-features">
          <div class="icon">
            <i class="icofont-culinary"></i>
          </div>
          <div class="features-content">
            <h3>Dine-In</h3>
            <p>Plan a perfect meal with friends and family, enjoy eating.</p>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-sm-6 col-md-6 pb-5">
        <div class="features-img">
          <img src="assets/img/features-img1.jpg" alt="features-img" />
        </div>
      </div>

      <div class="col-lg-4 col-sm-6 col-md-6">
        <div class="single-features">
          <div class="icon">
            <i class="icofont-noodles"></i>
          </div>
          <div class="features-content">
            <h3>Chinese</h3>
            <p>Do you love Chinese? Taste the best ever savour.</p>
          </div>
        </div>

        <div class="single-features">
          <div class="icon">
            <i class="icofont-cucumber"></i>
          </div>
          <div class="features-content">
            <h3>Earthen Oven</h3>
            <p>A great taste of Odisha is also here, just go for it.</p>
          </div>
        </div>

        <div class="single-features">
          <div class="icon">
            <i class="icofont-juice"></i>
          </div>
          <div class="features-content">
            <h3>Mocktails</h3>
            <p>Let's have some lemonade that refreshes us.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
